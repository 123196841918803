import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ServiceService } from './service.service'
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, private CookieService: CookieService, public ServiceService: ServiceService) {
  }
  title = 'trinqoo Admin';
  ngOnInit() {
    var OneSignal = window['OneSignal'] || [];
    OneSignal.push(["init", {
      appId: "3ef98ad0-dd09-4461-b2eb-de83ff09176a",
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: true,
      }
    }]);
    OneSignal.push(function () {
      OneSignal.push(["registerForPushNotifications"])
    });
    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        OneSignal.getUserId().then(function (userId) {
        });
      });
    });
  }
}
