import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AzureApiService {
  configs: any;
  constructor(handler: HttpBackend, private http: HttpClient) {
    this.http = new HttpClient(handler)
  }
  get ApiUrl() {
    return this.configs.ApiEndpoint
  }
  get configLoadedFrom() {
    return this.configs.configLoadedFrom
  }
  public async loadConfig(): Promise<any> {
    return this.http
      .get('assets/config.json').pipe(settings => settings)
      .toPromise()
      .then(settings => {
        this.configs = settings as configs;
        
      });
  }
}
export interface configs {
  ApiEndPoint: string;
  ConfigLoadedFrom: string
}
