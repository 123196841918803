import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { CarDashboardComponent } from './car-modules/car-dashboard/car-dashboard.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login', 
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/+home/home.module').then(m => m.HomeModule),
    // loadChildren: './modules/+home/home.module#HomeModule'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/+auth/auth-module').then(m => m.AuthModule)
    // loadChildren: './modules/+auth/auth-module#AuthModule'
  },
  {
    path: 'car',
    loadChildren: () => import('./car-modules/car-modules.module').then(m => m.CarModulesModule)
    // loadChildren: './car-modules/car-modules.module#CarModulesModule'
  },
  {
    path: 'global',
    loadChildren: () => import('./global-modules/global-modules.module').then(m => m.GlobalModulesModule)
    // loadChildren: './global-modules/global-modules.module#GlobalModulesModule'
  },
  {
    path: 'affiliate',
    loadChildren: () => import('./affiliate-modules/common-container/common-container.module').then(m => m.CommonContainerModule)
    // loadChildren: './global-modules/global-modules.module#GlobalModulesModule'
  },
  {
    path: 'marketing',
    loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule)
    // loadChildren: './global-modules/global-modules.module#GlobalModulesModule'
  }

  // { path: 'car-dashboard', component: CarDashboardComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
