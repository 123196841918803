import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Inject, Injectable, } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Ng2TelInputModule } from 'ng2-tel-input';
// import { CarDashboardComponent } from './car-modules/car-dashboard/car-dashboard.component';
import { CookieService } from 'ngx-cookie-service';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { AzureApiService } from './azure-services/azure-api.service';
import { StartBookingComponent } from './modules/+booking/components/start-booking/start-booking.component';
// import { DashboardNewComponent } from './modules/+dashboard/components/dashboard-new/dashboard-new.component';
@NgModule({
  declarations: [
    AppComponent,
    // DashboardNewComponent,
    // CarDashboardComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Ng2TelInputModule,
  ],
  exports: [],
  providers: [CookieService,
    {
      provide: APP_INITIALIZER,
      deps: [AzureApiService],
      useFactory: appInitializerFactory,
      multi: true
    }],
  // , { provide: LocationStrategy, useClass: HashLocationStrategy }
  bootstrap: [AppComponent]
})
export class AppModule { }
export function appInitializerFactory(ServiceService: AzureApiService): Function {
  return () => ServiceService.loadConfig();
};
